const breakpoints = {
  xl: {
    max: 1000000000,
    min: 1200,
  },
  lg: {
    max: 1199,
    min: 992,
  },
  md: {
    max: 991,
    min: 768,
  },
  sm: {
    max: 767,
    min: 576,
  },
  xs: {
    max: 575,
    min: 0,
  },
};

export default breakpoints;
